import * as React from 'react';
import { Text, Page as BrokenPage, StyleSheet } from '@react-pdf/renderer';
import { StyleOptions, BackupType } from '../../../models/PDFTableTypes';
import { BackupReport } from '../../../models/BackupReport';

const Page: any = BrokenPage;

import Title from './Title';
import Description from './Description';
import Table from './Table';

interface Props {
    index?: boolean;
    companyName: string;
    date: string;
    data: BackupReport[];
    header?: string;
    subheader?: string | string[];
    options: StyleOptions[];
    type: BackupType;
}

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFFFFF",
        color: "black",
        padding: 30
    },
    paginate: {
        textAlign: 'right',
        fontSize: 10,
        marginBottom: '1cm'
    }
});

class ReportPage extends React.Component<Props> {
    public render() {
        return (
            <Page
                size="A4"
                style={styles.page}
                orientation={
                    (this.props.type === 'normal')
                        ? 'portrait'
                        : 'landscape'
                }
            >
                <Text
                    style={styles.paginate}
                    render={({ pageNumber }) => pageNumber}
                    fixed
                />
                {this.props.index && <Title companyName={this.props.companyName} date={this.props.date} />}
                <Description
                    heading={this.props.header}
                    subheading={this.props.subheader}
                />
                <Table
                    data={this.props.data}
                    options={this.props.options}
                    type={this.props.type}
                />
            </Page>
        );
    }
}

export default ReportPage;