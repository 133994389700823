import * as React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { FormControl } from 'react-bootstrap';

import ExportAllButton from './ExportAllButton';

interface Props { }

interface Criteria {
    bindFrom: string;
    bindTo: string;
    searchFrom: string;
    searchTo: string;
}

function PopoverCriteria(props: Props) {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);
    const [criteria, setCriteria] = React.useState<Criteria>({
        bindFrom: '',
        bindTo: '',
        searchFrom: '',
        searchTo: ''
    });

    const handleShowPopover = () => setIsPopoverOpen(!isPopoverOpen);

    const dateFormat = date => {
        if (date != "" && date != null && date != undefined) {
            let dates = new Date(date),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2),
                hour = ("0" + date.getHours()).slice(-2),
                min = ("0" + date.getMinutes()).slice(-2)
            let dataRs = dates.getFullYear() + mnth + day + hour + min + '00'
            return dataRs
        } else {
            return ""
        }
    }

    const handleChangeDate = (range: 'from' | 'to') => (date: any) => {
        const newDate = dateFormat(date);

        setCriteria(prevState => (
            (range === 'from')
                ? {
                    ...prevState,
                    bindFrom: date,
                    searchFrom: newDate
                } : {
                    ...prevState,
                    bindTo: date,
                    searchTo: newDate
                }
        ));
    };

    return (
        <React.Fragment>
            <Button
                id="somebutton"
                type="button"
                onClick={handleShowPopover}
                color="primary"
                size="sm"
            >
                Export All Reports
            </Button>
            <Popover
                style={{ maxWidth: '500px' }}
                placement="bottom"
                target="somebutton"
                isOpen={isPopoverOpen}
                toggle={handleShowPopover}
            >
                <PopoverHeader className="font-weight-bold">
                    Select Time Range
                </PopoverHeader>
                <PopoverBody>
                    <Row>
                        <Col className="font-weight-bold">From</Col>
                        <Col className="font-weight-bold">To</Col>
                    </Row>
                    <Row>
                        <Col>
                            <DatePicker
                                selected={criteria.bindFrom}
                                onChange={handleChangeDate('from')}
                                customInput={<FormControl className="menu-form custom-datepicker require-field" size="sm" placeholder="dd/mm/yyyy" />}
                                dateFormat="dd/MM/yyyy HH:mm"
                                placeholderText="dd/MM/yyyy HH:mm"
                                showTimeInput
                                isClearable
                            />
                        </Col>
                        <Col>
                            <DatePicker
                                selected={criteria.bindTo}
                                onChange={handleChangeDate('to')}
                                customInput={<FormControl className="menu-form custom-datepicker require-field" size="sm" placeholder="dd/mm/yyyy" />}
                                dateFormat="dd/MM/yyyy HH:mm"
                                placeholderText="dd/MM/yyyy HH:mm"
                                showTimeInput
                                isClearable
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <ExportAllButton
                                disabled={(criteria.searchFrom === '') || (criteria.searchTo === '')}
                                dateTimeFrom={criteria.searchFrom}
                                dateTimeTo={criteria.searchTo}
                            />
                        </Col>
                    </Row>
                </PopoverBody>
            </Popover>
        </React.Fragment>
    );
}

export default PopoverCriteria;