import * as React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as QueryString from "query-string";
import { Button, Card, Col, Form, Row, Table, Tab, Tabs } from "react-bootstrap";

//lib
import Swal from 'sweetalert2';
import * as _moment from "moment-timezone";

//component
import DBMonitorTable from './DBMonitorTable'

//models
import * as MonitorModels from '../../models/MonitorModels'

class DBbackupTab extends React.Component<any, { checkRequest: boolean, dataResult: Array<MonitorModels.MonitorList>, monitor_id: any, from: string, to: string }>{
    constructor(props: any) {
        super(props);
        this.state = {
            checkRequest: false,
            dataResult: [],
            monitor_id: '',
            from: '',
            to: ''
        }
    }

    componentDidMount() {

        if (this.props.monitor_id != undefined) {
            this.bindingData();
            this.setState({
                monitor_id: this.props.monitor_id
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {

        if (this.props.monitor_id != this.state.monitor_id
            || this.props.from != this.state.from
            || this.props.to != this.state.to) {
            this.bindingData();
            this.setState({
                monitor_id: this.props.monitor_id,
                from: this.props.from,
                to: this.props.to
            })
        }
    }

    bindingData() {
        var ls: any = localStorage.getItem("DB_BACKUP_APPLICATION");
        this.setState({
            dataResult: [],
            checkRequest: false
        })
        fetch("v1/db?monitor_id=" + this.props.monitor_id + "&date_time_from=" + this.props.from + "&date_time_to=" + this.props.to,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            }
        )
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                var dataCount = result.totalCount
                var dataResult: Array<MonitorModels.MonitorList> = []
                if (dataCount > 0) {
                    result.resultList.sort((a, b) => (a.backupStartDate < b.backupStartDate) ? 1 : ((b.backupStartDate < a.backupStartDate) ? -1 : 0))
                    for (var i = 0; i < result.resultList.length; i++) {
                        var data_: MonitorModels.MonitorList = {
                            name: (result.resultList[i].backupsetName != null || result.resultList[i].backupsetName != undefined ? result.resultList[i].backupsetName : ''),
                            type: (result.resultList[i].backupType != null || result.resultList[i].backupType != undefined ? result.resultList[i].backupType : ''),
                            size: (result.resultList[i].backupSize != null || result.resultList[i].backupSize != undefined ? (result.resultList[i].backupSize).toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''),
                            backup_time: (result.resultList[i].backupStartDate != null || result.resultList[i].backupStartDate != undefined ? _moment(result.resultList[i].backupStartDate).tz('Asia/Bangkok').format('MMM DD YYYY h:mm:ss A') : ''),
                            finish_time: (result.resultList[i].backupFinishDate != null || result.resultList[i].backupFinishDate != undefined ? _moment(result.resultList[i].backupFinishDate).tz('Asia/Bangkok').format('MMM DD YYYY h:mm:ss A') : ''),
                            duration_time: (result.resultList[i].duration != null || result.resultList[i].duration != undefined ? (result.resultList[i].duration).toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''),
                            ratio: (result.resultList[i].ratio != null || result.resultList[i].ratio != undefined ? (parseInt(result.resultList[i].ratio)).toLocaleString(undefined, { minimumFractionDigits: 0 }) : '')
                        }
                        dataResult.push(data_)
                    }
                }

                console.log(dataResult)
                this.props.get_data(dataResult)
                this.setState({
                    dataResult: dataResult,
                    checkRequest: true
                })
            },
                (error) => {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน',
                    })

                    this.props.get_data([])
                    this.setState({
                        dataResult: [],
                        checkRequest: true
                    })
                }
            )
    }

    render() {
        return (
            <div>
                {/*<div className="master-data-content">*/}
                {/*    <div className='trapezoid-head' style={{ width: '200px' }}>*/}
                {/*        <span>*/}
                {/*            DB Back up*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="mat-box">
                    <Row>
                        <Col>
                            <DBMonitorTable checkRequest={this.state.checkRequest} dataResult={this.state.dataResult} />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
} export default DBbackupTab;