export function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function formatYMDToDMY(dmy: string) {
    if (dmy != undefined && dmy != "" && dmy != null) {
        var year = dmy.substring(0, 4);
        var month = dmy.substring(5, 7);
        var day = dmy.substring(8, 10);
        var Datevalue = "";
        var datevalue = "";
        var UTC = new Date()
        if (dmy.length < 11) {
            datevalue = day + '-' + month + '-' + year

        } else {
            var hour = dmy.substring(11, 13);
            var m = dmy.substring(14, 16);
            var s = dmy.substring(17, 19);
            datevalue = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

            let localTime = toTimestamp(datevalue).valueOf() * 1000
            let localOffset = UTC.getTimezoneOffset() * 60000;
            let utc = localTime + localOffset;
            let thai = utc + (3600000 * 14);
            let dateUTCUse = new Date(thai);
            let dateUTCUseString = dateUTCUse.toLocaleString()

            datevalue = dateUTCUseString.split('/').join('-')
            datevalue = datevalue.split(',').join('  ');
        }
        return datevalue;
    } else {
        Datevalue = "";

        return Datevalue;
    }
}

function toTimestamp(strDate) {
    var datum = Date.parse(strDate);
    return datum / 1000;
}

export function formatDMY(dmy: string) {

    if (dmy != undefined && dmy != "" && dmy != null) {

        var dateUTC = ""

        dateUTC = dmy.split('.').join('/')
        var year = parseInt(dmy.substring(6, 10));
        var month = parseInt(dmy.substring(3, 5));
        var day = parseInt(dmy.substring(0, 2));
        var hour = parseInt(dmy.substring(11, 13));
        var m = parseInt(dmy.substring(14, 16));
        var s = parseInt(dmy.substring(17, 19));

        dateUTC = month + '/' + day + '/' + year + ' ' + hour + ':' + m + ':' + s

        var UTC = new Date()
        let localTime = toTimestamp(dateUTC).valueOf() * 1000
        let localOffset = UTC.getTimezoneOffset() * 60000;
        let utc = localTime + localOffset;
        let thai = utc + (3600000 * 14);
        let dateUTCUse = new Date(thai);
        let dateUTCUseString = dateUTCUse.toLocaleString('en-IN', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false })
        var datevalue = "";
        datevalue = dateUTCUseString.split('/').join('-')

        return datevalue.split(',').join('  ');

    } else {
        datevalue = "";

        return datevalue;
    }
}

export async function getRedirectAppPoolUrl() {
    var getURL = ""
    await fetch("/v1/env")
        .then(res => res.json())
        .then(
            (result) => {
                getURL = result.appPoolAPI
            },
            (error) => {
            }
        )
    return window.open(getURL, '_self')
}

export function checkExp(e: any) {
    var exp = new Date(e.exp * 1000)
    var date_now = Date.now();
    var now = new Date(date_now)
    if (exp < now) {
        getRedirectAppPoolUrl()
    }
}

export function parseDateFromDatePicker(date: string): string {
    if (date === "") {
        return "Invalid date";
    } else {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ];

        const year = Number(date.substring(0, 4));
        const month = Number(date.substring(4, 6));
        const day = Number(date.substring(6, 8));

        const dateString = `${day} ${months[month - 1]} ${year}`;

        return dateString;
    }
}