import * as React from 'react';
import { pdf, Document as BrokenDocument } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { BackupReport } from '../models/BackupReport';
import { BackupType, StyleOptions } from '../models/PDFTableTypes';
import { parseDateFromDatePicker } from '../modules/common';
import ReportPage from '../components/button/miscPDF/ReportPage';

const Document: any = BrokenDocument;

type PrepareDocumentType = {
    header?: string;
    subheader?: string | string[];
    data: BackupReport[];
    options: StyleOptions[];
    type: BackupType;
};

interface FileOptions {
    name: string;
    from: string;
    to: string;
}

interface DocumentReportProps {
    companyName: string;
    date: string;
    content: PrepareDocumentType[];
}

export type ExportReport = (appVMBackupData, dbVMBackupData, azureBackupData, dbBackupData, fileOptions: FileOptions) => any;

class DocumentReport extends React.Component<DocumentReportProps> {
    public render() {
        return (
            <Document>
                {this.props.content.map((item, index) => (
                    <ReportPage
                        key={index}
                        index={index === 0}
                        date={this.props.date}
                        companyName={this.props.companyName}
                        header={item.header || ''}
                        subheader={item.subheader || ''}
                        data={item.data}
                        options={item.options}
                        type={item.type}
                    />
                ))}
            </Document>
        );
    }
}

function withExportPDF(WrappedComponent) {
    const options: StyleOptions[] = [
        {
            header: 'No.',
            property: 'no',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '5%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Name',
            property: 'name',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '60%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Size (Byte)',
            property: 'size',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '15%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Start Time',
            property: 'backup_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '20%',
                height: '100%'
            }
        }
    ];
    const logOptions: StyleOptions[] = [
        {
            header: 'No.',
            property: 'no',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '5%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Name',
            property: 'name',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '30%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Type',
            property: 'type',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '8%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Size (b)',
            property: 'size',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '10%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Start Time',
            property: 'backup_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '16%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'End Time',
            property: 'finish_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '16%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Duration (s)',
            property: 'duration_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '6%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'bps',
            property: 'ratio',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '9%',
                height: '100%'
            }
        }
    ];

    const exportReport: ExportReport = (appVMBackupData, dbVMBackupData, azureBackupData, dbBackupData, fileOptions: FileOptions) => {
        const content: PrepareDocumentType[] = [
            {
                header: "Focus One Application",
                subheader: "Google VM Backup",
                data: appVMBackupData,
                options: options,
                type: 'normal'
            },
            {
                header: "Database VM",
                subheader: "Google VM Backup",
                data: dbVMBackupData,
                options: options,
                type: 'normal'
            },
            {
                subheader: "DB Backup to Azure blob storage",
                data: azureBackupData,
                options: options,
                type: 'normal'
            },
            {
                subheader: "DB Backup Log",
                data: dbBackupData,
                options: logOptions,
                type: 'log'
            }
        ];

        const dateFrom = parseDateFromDatePicker(fileOptions.from);
        const dateTo = parseDateFromDatePicker(fileOptions.to);
        const outputDate =
            (dateFrom !== "Invalid date" && dateTo !== "Invalid date")
                ? `${dateFrom} - ${dateTo}`
                : "";

        const blob = pdf((
            <DocumentReport
                companyName={fileOptions.name}
                content={content}
                date={outputDate}
            />
        )).toBlob();
        const filename = `${fileOptions.name}_BackupMonitor_${new Date().getTime().toString()}.pdf`;
        //saveAs(blob, `${fileOptions.name}_BackupMonitor_${new Date().getTime().toString()}.pdf`);

        return {
            blob,
            filename
        };
    }

    return class ComponentWithExportPDF extends React.Component<any> {
        public render() {
            return <WrappedComponent exportReport={exportReport} {...this.props} />
        }
    }
}

export default withExportPDF;