import * as LoadingStore from './Loading'
import * as LoadingModels from '../models/Loading'

import * as MonitorStore from './Monitor';
import * as MonitorModels from '../models/MonitorModels';

import * as AppState from './AppState'
import * as ModelsAppState from '../models/AppState'

// The top-level state object
export interface ApplicationState {
    loading: LoadingModels.LoadingState | undefined;
    appState: ModelsAppState.AppState | undefined;
    monitor: MonitorModels.MonitorStoreState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    loading: LoadingStore.reducer,
    appState: AppState.reducer,
    monitor: MonitorStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
