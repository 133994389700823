import * as React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface Props {
    companyName: string;
    date: string;
}

const styles = StyleSheet.create({
    titleContainer: {
        textAlign: 'center',
        lineHeight: 2
    },
    titleHeader: {
        fontSize: 16
    },
    titleSubHeader: {
        fontSize: 14
    }
});

class Title extends React.Component<Props> {
    public render() {
        return (
            <View style={styles.titleContainer}>
                <Text style={styles.titleHeader}>
                    {this.props.companyName} Backup Report
                </Text>
                <Text style={styles.titleSubHeader}>
                    {this.props.date}
                </Text>
            </View>
        );
    }
}

export default Title;