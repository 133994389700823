import * as React from 'react';
import { Container } from 'reactstrap';
import SubLayout from './SubLayout';
import Home from './Home';
import Report from './Report';
import Ingress from './Ingress'
import { Route, Switch } from 'react-router';
import { PrivateRoute } from './PrivateRoute';
import PageLayout from './PageLayout';

import { connect } from "react-redux";
import { ApplicationState } from '../store';

export default (props: { children?: React.ReactNode }, store) => (
    <React.Fragment>
        <Switch>
            <Route path='/ingress' component={Ingress} />
            <PageLayout>
                <PrivateRoute exact path='/' component={Home} />
                <PrivateRoute path='/report' component={Report} />
            </PageLayout>
        </Switch>
    </React.Fragment>
);
