import { Action, Reducer, createStore } from 'redux';
import { useSelector, useDispatch } from "react-redux";
import { AppThunkAction } from '.';
// models
import * as Models from '../models/Loading'

interface SetLoadingOpen {
    type: 'SET_LOADING_TRUE'
}

interface SetLoadingClose {
    type: 'SET_LOADING_FALSE'
}

type KnownAction = SetLoadingOpen | SetLoadingClose

export const actionCreators = {
    UseLoading: (check: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (check != undefined) {
            console.log("Loading is : " + check);
            if (check == true) {
                dispatch({ type: 'SET_LOADING_TRUE' });
            } else {
                dispatch({ type: 'SET_LOADING_FALSE' });
            }
        }
    }
}

const unloadedState: Models.LoadingState = { statusLoading: false };

export const reducer: Reducer<Models.LoadingState> = (state: Models.LoadingState | undefined, incomingAction: Action): Models.LoadingState => {
    if (state == undefined) {
        return unloadedState;
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_LOADING_TRUE':
            return {
                statusLoading: true,
            }

        case 'SET_LOADING_FALSE':
            return {
                statusLoading: false,
            }
        default: return state;
    }
}