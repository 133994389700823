import * as React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { StyleOptions, BackupType } from '../../../models/PDFTableTypes';
import { BackupReport } from '../../../models/BackupReport';

interface Props {
    data: BackupReport[];
    options: StyleOptions[];
    type: BackupType;
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        alignItems: 'center',
        fontSize: 9,
        fontStyle: 'bold'
    }
});

class TableRow extends React.Component<Props> {
    public render() {
        const rows = this.props.data.map((item: any, rowIndex) => {
            const columns = this.props.options.map((option, columnIndex) => (
                <Text key={columnIndex} style={option.style}>
                    {
                        (option.property === 'no')
                            ? rowIndex + 1
                            : (item[option.property] !== undefined)
                                ? item[option.property]
                                : null
                    }
                </Text>
            ));

            return (
                <View
                    key={rowIndex}
                    style={[
                        styles.row,
                        {
                            borderTopWidth: (this.props.type === 'normal')
                                ? ((rowIndex + 1) % 41) === 0
                                    ? 1
                                    : 0
                                : (this.props.type === 'log')
                                    ? ((rowIndex + 1) % 25) === 0
                                        ? 1
                                        : 0
                                    : 0
                        }
                    ]}
                    break={
                        (this.props.type === 'normal')
                            ? ((rowIndex + 1) % 41) === 0
                                ? true
                                : false
                            : (this.props.type === 'log')
                                ? ((rowIndex + 1) % 25) === 0
                                    ? true
                                    : false
                                : false
                    }
                >
                    {columns}
                </View>
            );
        });

        return rows;
    }
}

export default TableRow;