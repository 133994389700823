import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import NavMenu from './NavMenu';
import './sidebar/style/sidebar.css';
import './PageComponent.css';
import PageComponent from './PageComponent';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />
        <div>
            <Container fluid={true} style={{ minHeight: "90vh" }}>
                <Row>
                    <Col md="12" style={{ marginLeft: 0 }}>
                        <PageComponent style={{ minHeight: "90vh" }}>
                            {props.children}
                        </PageComponent>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
);
