import * as React from "react";
import * as QueryString from "query-string"
import { Redirect } from "react-router";

class Ingress extends React.Component<any> {
    constructor(props: any) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.history.pushState({}, "Ingress", "/");
        //console.log("/Ingress")
        //const { wfId, docNo, wfInstanceNo, userId } = this.props.match.params
        const { token, redirect } = QueryString.parse(this.props.location.search);

        var jwt = this.parseJwt(token)
        var extra = JSON.parse(jwt.extra)
        var data = {
            access_token: token,
            /*            user_id: extra.user_id*/
        }

        localStorage.removeItem('DB_BACKUP_APPLICATION');
        localStorage.setItem('DB_BACKUP_APPLICATION', JSON.stringify(data));

        if (redirect != null) {
            console.log(redirect)
            var encode: string = atob(String(redirect))
            return window.top.location.href = encode;
        }
    }

    parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    componentDidUpdate() {
    }

    render() {
        return <Redirect to='/' />
    }
}

export default Ingress
