import * as React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import * as QueryString from "query-string";
import { Button, Card, Col, Form, Row, Table, Tab, Tabs, Spinner } from "react-bootstrap";

//models
import * as MonitorModels from '../../models/MonitorModels'

class MonitorTable extends React.Component<any, { checkBinding: boolean }>{
    constructor(props: any) {
        super(props);
        this.state = {
            checkBinding: true
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        if (this.props.checkRequest && this.state.checkBinding) {
            this.setState({
                checkBinding: false
            })
        }
    }

    render() {
        console.log(this.props.checkRequest)
        console.log(this.props.dataResult)
        console.log(this.props.dataResult.length)
        return (
            <div>
                <Table className='table-striped table-hover' >
                    <tr className='table-active'>
                        <th style={{ width: '60%' }} >Name</th>
                        <th style={{ width: '20%' }} >Size (bytes)</th>
                        <th style={{ width: '20%' }} >Back up Time</th>
                    </tr>
                    {!this.props.checkRequest ? <tbody><tr><td colSpan={3} style={{ background: "rgba(209, 250, 221, 1)", color: "black", fontSize: '10pt', textAlign: "center" }}><Spinner animation="border" size="sm" />&nbsp;&nbsp;Loading...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                    {this.props.checkRequest && this.props.dataResult.length == 0 ? <tbody><tr><td colSpan={3} style={{ background: "#b7b7a4", color: "black", fontSize: '10pt', textAlign: "center" }}>&nbsp;&nbsp;No Data...</td></tr></tbody> : <tbody><tr></tr></tbody>}
                    {this.props.checkRequest && this.props.dataResult.length != 0 && this.props.dataResult.map((data: MonitorModels.MonitorList, index) => {
                        return (
                            <tr key={index} >
                                <td>{data.name}</td>
                                <td>{data.size}</td>
                                <td>{data.backup_time}</td>
                            </tr>
                        )
                    })}
                </Table>
            </div>
        )
    }
} export default MonitorTable;