import * as React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { StyleOptions, BackupType } from '../../../models/PDFTableTypes';
import { BackupReport } from '../../../models/BackupReport';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

interface Props {
    data: BackupReport[];
    options: StyleOptions[];
    type: BackupType;
}

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 12
    }
});

class Table extends React.Component<Props> {
    public render() {
        return (
            <View style={styles.tableContainer}>
                <TableHeader options={this.props.options} />
                <TableRow
                    data={this.props.data}
                    options={this.props.options}
                    type={this.props.type}
                />
            </View>
        );
    }
}

export default Table;