import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

class Home extends React.Component<any> {

    constructor(props: any) {

        super(props);

        this.state = {
        }
    }

    render() {
        return (<Redirect to='/report' />);
    }
}

export default connect()(Home);
