import * as React from 'react';
import { Router, Route, RouteProps, Redirect } from 'react-router';
import { getRedirectAppPoolUrl } from '../modules/common';

export const PrivateRoute = ({ component: Component, ...rest }: any) => (
    <Route {...rest} render={props => (
        localStorage.getItem('DB_BACKUP_APPLICATION')
            ? <Component {...props} />
            : getRedirectAppPoolUrl()
    )} />
)
