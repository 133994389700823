import * as React from 'react';
import { Button, Row, Col, Tab, Table, Form } from "react-bootstrap";
import { FaSync, FaFileExport, FaTimesCircle, FaCheck, FaCheckCircle } from "react-icons/fa";
import Swal from 'sweetalert2';
//import { ExcelExport } from '@progress/kendo-react-excel-export';
//import { ExcelExportColumn } from '@progress/kendo-react-excel-export/dist/es/main';
import * as XLSX from 'xlsx';

class ExportExcelButton extends React.Component<any, { }> {
    constructor(props: any) {
        super(props);
        this.state = { }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
    }

    exportExcel = () => {
        var appVMBackupData = this.props.appVMBackupData
        var dbVMBackupData = this.props.dbVMBackupData
        var azureBackupData = this.props.azureBackupData
        var dbBackupData = this.props.dbBackupData

        var wb = XLSX.utils.book_new();
        var date = new Date();
        var filename = 'BackupMonitor_' + date.getTime().toString() + '.xlsx';
        var ExportAppVMBackupData: any = [];
        appVMBackupData.map((item) => {
            let data = {}
            data["Name"] = item.name;
            data["Size (bytes)"] = item.size;
            data["Backup Time"] = item.backup_time;
            ExportAppVMBackupData.push(data)
        })

        var wsAppVMBackup = XLSX.utils.json_to_sheet(ExportAppVMBackupData);
        XLSX.utils.book_append_sheet(wb, wsAppVMBackup, "App GCP Disk Backup");

        var ExportDbVMBackupData: any = [];
        dbVMBackupData.map((item) => {
            let data = {}
            data["Name"] = item.name;
            data["Size (bytes)"] = item.size;
            data["Backup Time"] = item.backup_time;
            ExportDbVMBackupData.push(data)
        })

        var wsDbVMBackupData = XLSX.utils.json_to_sheet(ExportDbVMBackupData);
        XLSX.utils.book_append_sheet(wb, wsDbVMBackupData, "DB GCP Disk Backup");

        var ExportAzureBackupData: any = [];
        azureBackupData.map((item) => {
            let data = {}
            data["Name"] = item.name;
            data["Size (bytes)"] = item.size;
            data["Backup Time"] = item.backup_time;
            ExportAzureBackupData.push(data)
        })

        var wsAzureBackup = XLSX.utils.json_to_sheet(ExportAzureBackupData);
        XLSX.utils.book_append_sheet(wb, wsAzureBackup, "DB Azure Blob Storage Backup");

        var ExportDbBackupData: any = [];
        dbBackupData.map((item) => {
            let data = {}
            data["Name"] = item.name;
            data["Type"] = item.type;
            data["Size (bytes)"] = item.size;
            data["Backup Time"] = item.backup_time;
            data["Finish Backup Time"] = item.finish_time;
            data["Duration (s)"] = item.duration_time;
            data["Byte/s"] = item.ratio;
            ExportDbBackupData.push(data)
        })

        var wsDbBackup = XLSX.utils.json_to_sheet(ExportDbBackupData);
        XLSX.utils.book_append_sheet(wb, wsDbBackup, "DB Backup Log");


        XLSX.writeFile(wb, filename);

        //document.getElementsByClassName('Loader__background')[0].className = "Loader__background hide";
    }

    render() {
        console.log(this.props.monitorId)
        console.log(this.props.from)
        console.log(this.props.to)
        return (
            <Button variant="outline-success" size="sm" onClick={() => this.exportExcel()} >
                <FaFileExport />
                &nbsp;Export Excel
            </Button>
        )
    }
}

export default ExportExcelButton;