import * as QueryString from "query-string";
import * as React from 'react';
import { Table, Col, Row, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import AzurebackupTab from './monitorTab/AzurebackupTab';
//component
import DBbackupTab from './monitorTab/DBbackupTap';
import VMbackupTap from './monitorTab/VMbackupTap';
import ExportReportButton from './button/ExportReportButton';
import ExportExcelButton from './button/ExportExcelButton';
import SearchCriteria from './criteria/SearchCriteria';

//models
import * as MonitorModels from '../models/MonitorModels'

class Report extends React.Component<any, {
    version: string, monitor_db_id: any, monitor_app_id: any, from: any, to: any,
    appVMBackupData: Array<MonitorModels.MonitorList>, dbVMBackupData: Array<MonitorModels.MonitorList>,
    azureBackupData: Array<MonitorModels.MonitorList>, dbBackupData: Array<MonitorModels.MonitorList>
}> {

    constructor(props: any) {

        super(props);

        this.state = {
            version: '',
            monitor_db_id: '',
            monitor_app_id: '',
            from: '',
            to: '',
            appVMBackupData: [],
            dbVMBackupData: [],
            azureBackupData: [],
            dbBackupData: []
        }
    }

    componentDidMount() {
        let { monitor_db_id, monitor_app_id } = QueryString.parse(this.props.location.search)

        let monitor_db_id_: any = monitor_db_id != null ? monitor_db_id.toString : ''
        let monitor_app_id_: any = monitor_app_id != null ? monitor_app_id.toString : ''

        window.history.pushState({}, "Backup Report", "/report");

        if (monitor_db_id != undefined || monitor_app_id != undefined) {
            this.setState({
                monitor_db_id: monitor_db_id_,
                monitor_app_id: monitor_app_id_
            })
        }
    }

    componentDidUpdate(nextProps: any, nextState: any) {
        var { monitor_db_id, monitor_app_id } = QueryString.parse(this.props.location.search)

        console.log('ngame', monitor_db_id, monitor_app_id)

        if ((monitor_db_id != this.state.monitor_db_id && monitor_db_id != undefined)
            || (monitor_app_id != this.state.monitor_app_id && monitor_app_id != undefined)) {
            this.setState({
                monitor_db_id: monitor_db_id,
                monitor_app_id: monitor_app_id
            })
            window.history.pushState({}, "Backup Report", "/report");
        }
    }

    handleSearch = (from: string, to: string) => {
        this.setState({
            from,
            to
        });
    }

    handleAppVMbackupTapReturnData = (data: Array<MonitorModels.MonitorList>) => {
        console.log("AppVMbackupTapReturnData");
        console.log(data)
        this.setState({
            appVMBackupData: data
        });
    }

    handleDbVMbackupTapReturnData = (data: Array<MonitorModels.MonitorList>) => {
        console.log("DbVMbackupTapReturnData");
        console.log(data)
        this.setState({
            dbVMBackupData: data
        });
    }

    handleDbAzurebackupTabReturnData = (data: Array<MonitorModels.MonitorList>) => {
        console.log("DbAzurebackupTabReturnData");
        console.log(data)
        this.setState({
            azureBackupData: data
        });
    }

    handleDBbackupTapReturnData = (data: Array<MonitorModels.MonitorList>) => {
        console.log("DBbackupTapReturnData");
        console.log(data)
        this.setState({
            dbBackupData: data
        });
    }

    render() {
        return (
            <div>
                {(this.state.monitor_db_id == '' && this.state.monitor_app_id == '') && <h6>โปรดเลือกบริษัทที่ต้องการ..</h6>}
                {this.state.monitor_app_id != '' &&
                    <Table>
                        <Row>
                            <Col>
                                <SearchCriteria handleSearch={this.handleSearch} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                <ExportExcelButton monitorAppId={this.state.monitor_app_id} monitorDbId={this.state.monitor_db_id} from={this.state.from} to={this.state.to}
                                    appVMBackupData={this.state.appVMBackupData} dbVMBackupData={this.state.dbVMBackupData} azureBackupData={this.state.azureBackupData}
                                    dbBackupData={this.state.dbBackupData}
                                />
                                <ExportReportButton monitorAppId={this.state.monitor_app_id} monitorDbId={this.state.monitor_db_id} from={this.state.from} to={this.state.to}
                                    appVMBackupData={this.state.appVMBackupData} dbVMBackupData={this.state.dbVMBackupData} azureBackupData={this.state.azureBackupData}
                                    dbBackupData={this.state.dbBackupData}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Tabs defaultActiveKey="AppVMbackupTap" id="tab">
                                    <Tab eventKey="AppVMbackupTap" title="App GCP Disk Back up">
                                        <VMbackupTap monitor_id={this.state.monitor_app_id} from={this.state.from} to={this.state.to} get_data={this.handleAppVMbackupTapReturnData} />
                                    </Tab>
                                    {this.state.monitor_db_id != '' &&
                                        <Tab eventKey="DbVMbackupTap" title="DB GCP Disk Back up">
                                            <VMbackupTap monitor_id={this.state.monitor_db_id} from={this.state.from} to={this.state.to} get_data={this.handleDbVMbackupTapReturnData} />
                                        </Tab>
                                    }
                                    {this.state.monitor_db_id != '' &&
                                        <Tab eventKey="DbAzurebackupTab" title="DB Azure Blob Storage Back up">
                                            <AzurebackupTab monitor_id={this.state.monitor_db_id} from={this.state.from} to={this.state.to} get_data={this.handleDbAzurebackupTabReturnData} />
                                        </Tab>
                                    }
                                    {this.state.monitor_db_id != '' &&
                                        <Tab eventKey="DBbackupTap" title="DB Back up Log" >
                                            <DBbackupTab monitor_id={this.state.monitor_db_id} from={this.state.from} to={this.state.to} get_data={this.handleDBbackupTapReturnData} />
                                        </Tab>
                                    }
                                </Tabs>
                            </Col>
                        </Row>
                    </Table>
                }

            </div>
        );
    }
}

export default connect()(Report)
