import * as React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

interface Props {
    heading?: string,
    subheading?: string | string[]
}

const styles = StyleSheet.create({
    desContainer: {
        marginTop: 15,
        lineHeight: 1.5
    }
});

class Description extends React.Component<Props> {
    public render() {
        return (
            <View style={styles.desContainer}>
                <Text style={{ fontSize: 14 }}>
                    {this.props.heading}
                </Text>
                {
                    (Array.isArray(this.props.subheading))
                        ? (
                            this.props.subheading.map((message, index) => (
                                <Text key={index} style={{ fontSize: 12 }}>
                                    {message}
                                </Text>
                            ))
                        ) : (
                            <Text style={{ fontSize: 12 }}>
                                {this.props.subheading}
                            </Text>
                        )
                }
            </View>
        );
    }
}

export default Description;