import * as React from 'react';
import { Button, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Popover, PopoverBody, PopoverHeader, Row } from 'reactstrap';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { FaRegListAlt, FaSlidersH, FaUserCircle, FaEllipsisH } from "react-icons/fa";
import { checkExp, parseJwt, getRedirectAppPoolUrl } from '../modules/common';
import Swal from 'sweetalert2';

import { ApplicationState } from "../store";
import { connect } from "react-redux";

//var imgFile = require('./image/f1-new-logo.png');
import imgFile from './image/f1-new-logo.png';
import ExportReportButton from './button/ExportReportButton';
import PopoverCriteria from './criteria/PopoverCriteria';

class NavMenu extends React.Component<any, { isOpen: boolean, version: string, user: string, checkTypeUser: string, isPopoverOpen: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {
            isOpen: false,
            version: '',
            user: '',
            checkTypeUser: "user",
            isPopoverOpen: false
        };
    }

    componentDidMount() {
        //localStorage.setItem('LG_APPLICATION', '{"user_id":"555","access_token":"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjFwbXVveDhJTjdoZEcwV3pJUFZxTXdiMnhUZSIsInNlciI6IlMwMDAyIiwiYXBwIjoiMW1IVVNYTktlR25tM3lNejRrOXZEb0RCQTNiIiwia2V5IjoiMXBtdW9waEZIU21hUExITER6d3g3NmpGVW5OIiwiZXhwIjoxOTMxMzcwMDI0LCJpYXQiOjE2MTU4MDA3NjQsImlzcyI6IlNPTkFSIElOTk9WQVRJT04gQ08uLCBMVEQuIn0.l2T9zbgSVrmJ9v89KNBhLAcSsKjrWzfyOgyAEtDwKrwytyU8F6acVz72ocZyeEoo0JJ8zyWKtNYVBNLZtVXYlgwfXb7nXlMcnK8fmPzykH4nYBdoSvoEvbR4I5RilYgLBTaWkT3P2rqu2G1_LWFfAfEAEFoDvj8Y5dhS4LUfKkM","company_code" : "2580"}');
        var ls: any = localStorage.getItem("DB_BACKUP_APPLICATION");
        var userId = "";
        var checkUser = "";

        if (ls) {
            var jls: any = JSON.parse(ls)
            var accessToken = jls.access_token
            var jwt = parseJwt(accessToken);
            var extra = JSON.parse(jwt.extra);
            checkExp(jwt)

            if (extra.username) {
                userId = extra.username
                checkUser = extra.username
            }
            else if (extra.first_name) {
                userId = extra.first_name + " " + extra.last_name
                checkUser = extra.citizen_id
            }
            else if (extra.citizen_id) {
                userId = extra.citizen_id
                checkUser = extra.citizen_id
            }

            if (checkUser == "") {
                Swal.fire({
                    title: 'เกิดข้อผิดพลาด',
                    text: 'ไม่พบ user ใน token',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'ตกลง',
                }).then((result) => { getRedirectAppPoolUrl() })
            }
            this.setState({ user: userId })
        }

        fetch("/v1/version")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        version: result.message
                    });
                },
                (error) => {
                    console.log(error)
                }
            )

        //fetch("/v1/user?id=" + userId,
        //    {
        //        headers: {
        //            'Content-Type': 'application/json',
        //            'authorization': 'Bearer ' + ls
        //        }
        //    }
        //)
        //    .then(res => res.json())
        //    .then(
        //        (result) => {
        //            console.log(result)
        //            var data = result.result_list
        //            if (data != null && data.length > 0) {
        //                this.setState({
        //                    checkTypeUser: data[0].list_company[0].role_access
        //                });
        //            } else {
        //                if (result.message == "No such host is known." || result.message == "An invalid request URI was provided. The request URI must either be an absolute URI or BaseAddress must be set.") {
        //                    Swal.fire({
        //                        title: '�Դ��ͼԴ��Ҵ',
        //                        text: '�������ö�������͡Ѻ server ��㹢�й��',
        //                        icon: 'error',
        //                        showCancelButton: false,
        //                        confirmButtonColor: '#3085d6',
        //                        confirmButtonText: '�׹�ѹ',
        //                    }).then((result) => { window.location.reload() })
        //                }
        //            }
        //        },
        //        (error) => {
        //            console.log(error)
        //        }
        //    )

        if (checkUser != "") {
            fetch("/v1/user?id=" + checkUser,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + ls
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log(result);
                        var data = result.totalCount
                        if (data == undefined || data == 0) {
                            Swal.fire({
                                title: 'เกิดข้อผิดพลาด',
                                text: 'ไม่พบ user ในระบบ',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'ตกลง',
                            }).then((result) => { getRedirectAppPoolUrl() })
                        }
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }
    }

    public togglePopover() {
        this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
    }

    public render() {
        return (
            <header>
                <Navbar className="bg-navbar navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
                    <div className="navbar-header">
                        <div className="navbar-brand" style={{ padding: '0', width: '700px' }}>
                            <div className="nav-brand-name" >
                                <Row>
                                    <div className="trapezoid-logo mx-2" style={{ color: '#adb5bd', display: 'inline-block' }}>
                                        {/*<img src={logo} style={{ width: '96px', height: '40px', cursor: 'pointer', marginTop: '4px;}} >*/}
                                        <a href={'/'}>
                                            {/*< img src={'https://slsn-o2c-uat.thailanderp.com/images/f1-new-logo.png'} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />*/}
                                            < img src={imgFile} style={{ marginTop: '4px', width: '96px', height: '40px' }} alt="file" />
                                        </a>
                                    </div>
                                    <div className="mx-4 mt-3">
                                        FocusOne Backup Monitor
                                        <span style={{ fontSize: '8pt', paddingLeft: '5px' }}>V {this.state.version}</span>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse font-weight-bold" isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav flex-grow d-flex align-items-center">
                            {/*                            <DropdownButton id="dropdown-basic-button" title={<FaUserCircle />} variant="outline-dark" size="sm">
                                <Dropdown.Item><Link to="/Login">Log In</Link></Dropdown.Item>
                            </DropdownButton>*/}
                            <NavItem>
                                <PopoverCriteria />
                            </NavItem>
                            <NavItem>
                                <NavLink className="text-dark font-navbar" >{this.state.user}</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark font-navbar" to="/logout">Back to Portal</NavLink>
                            </NavItem>
                            <div className="mx-2"></div>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
export default connect()(NavMenu);