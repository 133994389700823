import * as React from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { MonitorIds } from '../models/MonitorModels';
import Swal from 'sweetalert2';
import { ApplicationState } from '../store';

class PageComponent extends React.Component<any, { version: string, masterData: any }> {

    constructor(props: any) {

        super(props);

        this.state = {
            version: '',
            masterData: null
        }
    }

    componentDidMount() {
        var ls: any = localStorage.getItem("DB_BACKUP_APPLICATION");

        fetch("/v2/master/monitor?sid=*",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + ls
                }
            }
        )
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                let totalCount = result.totalCount
                let masterData = result.resultList

                // Save ids in store
                const monitor_ids: MonitorIds[] = masterData.map((data: any, index: number) => ({
                    group_name: data.groupName,
                    monitor_db_id: data.monitorList[0].id,
                    monitor_app_id: data.monitorList[1].id
                }));

                this.props.dispatch({ type: 'SET_MONITOR_IDS', monitor_ids });

                if (totalCount > 0) {
                    this.setState({
                        masterData
                    });
                }
            },
                (error) => {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด',
                        text: 'ไม่สามารถเชื่อมต่อกับ server ได้ในขณะนี้',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'ยืนยัน',
                    })
                }
            )
    }

    setSubMenu = () => {
        //console.log(this.state.masterData)
        if (this.state.masterData == null) {
            return <div></div>
        }

        return <div >
            <div className="sidebar-menu" style={{ borderBottom: '1px solid #e7e7e7', borderTop: '0px' }}>
                <ul>
                    <li className="sidebar-dropdown" >
                        {
                            this.state.masterData.map((data: any, index: number) => (
                                <Link
                                    key={index}
                                    to={{
                                        pathname: "/report",
                                        search: "?"
                                            + (data.monitorList[0].type == 'db'
                                                ? "monitor_db_id=" + data.monitorList[0].id
                                                : data.monitorList[0].type == 'app'
                                                    ? "monitor_app_id=" + data.monitorList[0].id
                                                    : "")
                                            + (data.monitorList[1].type == 'db'
                                                ? "&monitor_db_id=" + data.monitorList[1].id
                                                : data.monitorList[1].type == 'app' ? "&monitor_app_id=" + data.monitorList[1].id : "")
                                    }}
                                    onClick={() => this.props.dispatch({ type: 'SET_SELECTED_COMPANY', selected_company: data.groupName })}
                                >
                                    <span style={{ fontSize: '18px' }}>{data.groupName}</span>
                                </Link>
                            ))
                        }
                    </li>
                </ul>
            </div>
        </div>
    }

    render() {
        return (
            <div>
                <div className="page-wrapper chiller-theme toggled" id="setHeight">

                    <nav id="sidebar" className="sidebar-wrapper" style={{ marginTop: '-15px', width: 300, position: 'absolute' }}>
                        <div className="sidebar-content">
                            {this.setSubMenu()}
                        </div>
                    </nav>

                    <main className="page-content" style={{ marginTop: '-30px', paddingLeft: '300px', minHeight: "90vh" }}>
                        {this.props.children}
                    </main>

                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.monitor
)(PageComponent)
