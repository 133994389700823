import { Reducer, Action } from 'redux';
import { MonitorStoreState, MonitorIds } from '../models/MonitorModels';

interface SetMonitorIds {
    type: 'SET_MONITOR_IDS',
    monitor_ids: MonitorIds[]
}

interface SetSelectedCompany {
    type: 'SET_SELECTED_COMPANY',
    selected_company: string;
}

interface ClearMonitorIds {
    type: 'CLEAR_MONITOR_IDS'
}

type KnownAction =
    | SetMonitorIds
    | ClearMonitorIds
    | SetSelectedCompany;

const initialState: MonitorStoreState = {
    selected_company: '',
    monitor_ids: []
};

export const reducer: Reducer<MonitorStoreState, KnownAction> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MONITOR_IDS': {
            return {
                ...state,
                monitor_ids: action.monitor_ids
            };
        }
        case 'SET_SELECTED_COMPANY': {
            return {
                ...state,
                selected_company: action.selected_company
            }
        }
        case 'CLEAR_MONITOR_IDS': {
            return state;
        }
        default: return state;
    }
}