import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import * as moment from 'moment-timezone';
import { MonitorStoreState, MonitorList } from '../../models/MonitorModels';
import { ApplicationState } from '../../store';
import { saveAs } from 'file-saver';
import withExportPDF, { ExportReport } from '../../hocs/withExportPDF';

interface Props {
    disabled: boolean;
    dateTimeFrom: string;
    dateTimeTo: string;
    exportReport: ExportReport;
}

function ExportAllButton({ dateTimeFrom, dateTimeTo, exportReport, disabled }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { monitor_ids } = useSelector<ApplicationState, MonitorStoreState>(state => state.monitor as MonitorStoreState);

    // Sort each company report
    const sortReport = (companyReports: any[]): Array<MonitorList[]> => {
        const reportLength = companyReports.length;
        const newReports: Array<MonitorList[]> = [];

        for (let i = 0; i < reportLength; i++) {
            const currentReport = companyReports[i];
            const totalCount = currentReport.totalCount;

            if (i !== (reportLength - 1)) {
                const dataResult: MonitorList[] = [];

                if (totalCount > 0) {
                    currentReport.resultList.sort((a, b) => (a.createdOn < b.createdOn) ? 1 : ((b.createdOn < a.createdOn) ? -1 : 0));

                    for (let j = 0; j < currentReport.resultList.length; j++) {
                        const data: MonitorList = {
                            name: (currentReport.resultList[j].name != null || currentReport.resultList[j].name != undefined ? currentReport.resultList[j].name : ''),
                            type: '',
                            size: (currentReport.resultList[j].contentLength != null || currentReport.resultList[j].contentLength != undefined ? (currentReport.resultList[j].contentLength).toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''),
                            backup_time: (currentReport.resultList[j].createdOn != null || currentReport.resultList[j].createdOn != undefined ? (moment(currentReport.resultList[j].createdOn).tz('Asia/Bangkok').format('MMM DD YYYY h:mm:ss A')) : ''),
                            finish_time: '',
                            duration_time: '',
                            ratio: ''
                        }

                        dataResult.push(data);
                    }

                    newReports.push(dataResult);
                } else {
                    newReports.push([]);
                }
            } else {
                const dataResult: MonitorList[] = [];

                if (totalCount > 0) {
                    currentReport.resultList.sort((a, b) => (a.backupStartDate < b.backupStartDate) ? 1 : ((b.backupStartDate < a.backupStartDate) ? -1 : 0));

                    for (let j = 0; j < currentReport.resultList.length; j++) {
                        let data: MonitorList = {
                            name: (currentReport.resultList[j].backupsetName != null || currentReport.resultList[j].backupsetName != undefined ? currentReport.resultList[j].backupsetName : ''),
                            type: (currentReport.resultList[j].backupType != null || currentReport.resultList[j].backupType != undefined ? currentReport.resultList[j].backupType : ''),
                            size: (currentReport.resultList[j].backupSize != null || currentReport.resultList[j].backupSize != undefined ? (currentReport.resultList[j].backupSize).toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''),
                            backup_time: (currentReport.resultList[j].backupStartDate != null || currentReport.resultList[j].backupStartDate != undefined ? moment(currentReport.resultList[j].backupStartDate).tz('Asia/Bangkok').format('MMM DD YYYY h:mm:ss A') : ''),
                            finish_time: (currentReport.resultList[j].backupFinishDate != null || currentReport.resultList[j].backupFinishDate != undefined ? moment(currentReport.resultList[j].backupFinishDate).tz('Asia/Bangkok').format('MMM DD YYYY h:mm:ss A') : ''),
                            duration_time: (currentReport.resultList[j].duration != null || currentReport.resultList[j].duration != undefined ? (currentReport.resultList[j].duration).toLocaleString(undefined, { minimumFractionDigits: 0 }) : ''),
                            ratio: (currentReport.resultList[j].ratio != null || currentReport.resultList[j].ratio != undefined ? (parseInt(currentReport.resultList[j].ratio)).toLocaleString(undefined, { minimumFractionDigits: 0 }) : '')
                        }

                        dataResult.push(data);
                    }

                    newReports.push(dataResult);
                } else {
                    newReports.push([]);
                }
            }
        }

        return newReports;
    }

    // Alert! Non-blocking function - Get each company report from API
    const searchReports = (db_id: string, app_id: string): Promise<any[]> => {
        const token: string = localStorage.getItem("DB_BACKUP_APPLICATION") as string;
        const urls: string[] = ['/v1/google', '/v1/google', 'v1/azure', '/v1/db'];

        const promises: Promise<any>[] = [];

        for (let i = 0; i < urls.length; i++) {
            const searchParams = new URLSearchParams(
                {
                    monitor_id: (i === 0) ? app_id : db_id,
                    date_time_from: dateTimeFrom,
                    date_time_to: dateTimeTo
                } as any
            ).toString();

            const promise = fetch(urls[i] + '?' + searchParams, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`
                }
            });

            promises.push(promise);
        }

        return Promise
            .all(promises)
            .then(responses => {
                const jsonPromises = responses.map(response => response.json());
                return Promise.all(jsonPromises);
            });
    };

    // Alert! Non-blocking function
    const handleExportAllReports = async () => {
        setIsLoading(true);

        const promises: Promise<any>[] = [];

        // Phase 1 - Call API
        for (let i = 0; i < monitor_ids.length; i++) {
            const promise = searchReports(monitor_ids[i].monitor_db_id, monitor_ids[i].monitor_app_id);
            promises.push(promise);
        }

        const companyReports = await Promise.all(promises);
        const newCompanyReports: Array<MonitorList[][]> = [];

        // Phase 2 - Sort report
        for (let i = 0; i < companyReports.length; i++) {
            const companyReport = sortReport(companyReports[i]);
            newCompanyReports.push(companyReport);
        }

        const blobs: any[] = [];
        const filenames: string[] = [];

        // Phase 3 - Create blobs
        for (let i = 0; i < newCompanyReports.length; i++) {
            const currentCompanyReport = newCompanyReports[i];
            const { blob, filename } = exportReport(
                currentCompanyReport[0],
                currentCompanyReport[1],
                currentCompanyReport[2],
                currentCompanyReport[3],
                {
                    name: monitor_ids[i].group_name,
                    from: dateTimeFrom,
                    to: dateTimeTo
                }
            );

            blobs.push(blob);
            filenames.push(filename);
        }

        const fullfilledBlobs = await Promise.all(blobs);

        // Phase 4 - Create PDF
        for (let i = 0; i < fullfilledBlobs.length; i++) {
            saveAs(fullfilledBlobs[i], filenames[i]);
        }

        setIsLoading(false);
    };

    return (
        <Fragment>
            {
                (isLoading)
                    ? <Spinner color="primary" className="mt-3" />
                    : (
                        <Button
                            className="mt-3"
                            color="primary"
                            size="sm"
                            disabled={disabled}
                            onClick={handleExportAllReports}
                        >
                            Export
                        </Button>
                    )
            }
        </Fragment>
    );
}

export default withExportPDF(ExportAllButton);