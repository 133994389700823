import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Tab, Table, Form, Spinner } from "react-bootstrap";
import { FaSync, FaFileExport, FaTimesCircle, FaCheck, FaCheckCircle } from "react-icons/fa";
import { PDFDownloadLink, Document as BrokenDocument, pdf } from '@react-pdf/renderer';
import { BackupReport } from '../../models/BackupReport';
import { StyleOptions, BackupType } from '../../models/PDFTableTypes';
import { parseDateFromDatePicker } from '../../modules/common';
import { saveAs } from 'file-saver';

import ReportPage from './miscPDF/ReportPage';
import { ApplicationState } from '../../store';

const Document: any = BrokenDocument;

type PrepareDocumentType = {
    header?: string;
    subheader?: string | string[];
    data: BackupReport[];
    options: StyleOptions[];
    type: BackupType;
};

interface DocumentReportProps {
    companyName: string;
    date: string;
    content: PrepareDocumentType[];
}

class DocumentReport extends React.Component<DocumentReportProps> {
    public render() {
        return (
            <Document>
                {this.props.content.map((item, index) => (
                    <ReportPage
                        key={index}
                        index={index === 0}
                        companyName={this.props.companyName}
                        date={this.props.date}
                        header={item.header || ''}
                        subheader={item.subheader || ''}
                        data={item.data}
                        options={item.options}
                        type={item.type}
                    />
                ))}
            </Document>
        );
    }
}

class ExportReportButton extends React.Component<any, { loading: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false
        };
    }

    private options: StyleOptions[] = [
        {
            header: 'No.',
            property: 'no',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '5%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Name',
            property: 'name',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '60%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Size (Byte)',
            property: 'size',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '15%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Start Time',
            property: 'backup_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '20%',
                height: '100%'
            }
        }
    ];

    private logOptions: StyleOptions[] = [
        {
            header: 'No.',
            property: 'no',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '5%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Name',
            property: 'name',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '30%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Backup Type',
            property: 'type',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '8%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Size (b)',
            property: 'size',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '10%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Start Time',
            property: 'backup_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '16%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'End Time',
            property: 'finish_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '16%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'Duration (s)',
            property: 'duration_time',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '6%',
                height: '100%',
                borderRightWidth: 1
            }
        },
        {
            header: 'bps',
            property: 'ratio',
            style: {
                paddingLeft: 4,
                paddingVertical: 2,
                width: '9%',
                height: '100%'
            }
        }
    ];

    private async exportReport() {
        const content: PrepareDocumentType[] = [
            {
                header: "Focus One Application",
                subheader: "Google VM Backup",
                data: this.props.appVMBackupData,
                options: this.options,
                type: 'normal'
            },
            {
                header: "Database VM",
                subheader: "Google VM Backup",
                data: this.props.dbVMBackupData,
                options: this.options,
                type: 'normal'
            },
            {
                subheader: "DB Backup to Azure blob storage",
                data: this.props.azureBackupData,
                options: this.options,
                type: 'normal'
            },
            {
                subheader: "DB Backup Log",
                data: this.props.dbBackupData,
                options: this.logOptions,
                type: 'log'
            }
        ];

        this.setState({ loading: true });

        const dateFrom = parseDateFromDatePicker(this.props.from);
        const dateTo = parseDateFromDatePicker(this.props.to);
        const outputDate =
            (dateFrom !== "Invalid date" && dateTo !== "Invalid date")
                ? `${dateFrom} - ${dateTo}`
                : "";

        const blob = await pdf((
            <DocumentReport
                companyName={this.props.selected_company}
                content={content}
                date={outputDate}
            />
        )).toBlob();
        saveAs(blob, `BackupMonitor_${new Date().getTime().toString()}.pdf`);

        this.setState(prevState => ({ loading: false }));
    }

    render() {
        return (
            <React.Fragment>
                {
                    (this.state.loading)
                        ? (
                            <Spinner animation="border" variant="primary" size="sm" />
                        )
                        : (
                            <Button
                                variant="outline-primary"
                                style={{ margin: '10px' }} size="sm"
                                onClick={() => this.exportReport()}
                            >
                                <FaFileExport />
                                &nbsp;Export Report
                            </Button>
                        )
                }
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.monitor
)(ExportReportButton);