import * as React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { StyleOptions } from '../../../models/PDFTableTypes';

interface Props {
    options: StyleOptions[];
}

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        fontSize: 10
    }
});

class TableHeader extends React.Component<Props> {
    public render() {
        return (
            <View style={styles.container}>
                {this.props.options.map((option, index) => (
                    <Text key={index} style={option.style}>{option.header}</Text>
                ))}
            </View>
        );
    }
}

export default TableHeader;
