import * as React from 'react';
import { Container, Row, Col, Button, Form, FormControl, InputGroup, Modal, Table, Spinner, ButtonGroup } from 'react-bootstrap';
import { connect } from "react-redux";
import { FaSearch, FaRedoAlt, FaEye, FaFile, FaDownload } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/*import { formatDMY, formatYMDToDMY, parseJwt, taskStatusColour, submassage } from '../modules/common'*/


class SearchCriteria extends React.Component<any, { bindFrom: string, bindTo: string, searchFrom: string, searchTo: string }> {
    constructor(props: any) {
        super(props);
        this.state = {
            bindFrom: '',
            bindTo: '',
            searchFrom: '',
            searchTo: ''
        }
    }

    dateFormat(date) {
        if (date != "" && date != null && date != undefined) {
            var dates = new Date(date),
                mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                day = ("0" + date.getDate()).slice(-2),
                hour = ("0" + date.getHours()).slice(-2),
                min = ("0" + date.getMinutes()).slice(-2)
            var dataRs = dates.getFullYear() + mnth + day + hour + min + '00'
            return dataRs
        } else {
            return ""
        }
    }

    handleChangeFrom = (date, event) => {
        var newDate = ""
        if (date != null) {
            newDate = this.dateFormat(date);
        }
        //var dataReq = this.state.wfnoSearch
        //dataReq['createBy'] = dateDateBy
        this.setState({
            bindFrom: date,
            searchFrom: newDate
        })
    }

    handleChangeTo = (date, event) => {
        var newDate = ""
        if (date != null) {
            newDate = this.dateFormat(date);
        }
        //var dataReq = this.state.wfnoSearch
        //dataReq['createBy'] = dateDateBy
        this.setState({
            bindTo: date,
            searchTo: newDate
        })
    }

    render() {
        console.log('ngame date from', this.state.searchFrom);
        console.log('ngame date to', this.state.searchTo);

        return (
            <Row>
                <Col>
                    <div className='trapezoid trapezoid-purple' style={{ width: '200px', display: 'inline-block' }}>
                        <span>
                            <i className="fa fa-search" aria-hidden="true"></i>
                            ค้นหาข้อมูล
                        </span>
                    </div>
                    <div className="mat-box">
                        <Row>
                            <Col lg={1.5}>
                                <Form.Label className="font-weight-bold" style={{ marginLeft: "10px", marginTop: "2px" }} >Job Start From :</Form.Label>
                            </Col>
                            <Col lg={2}>
                                <DatePicker
                                    selected={this.state.bindFrom}
                                    onChange={this.handleChangeFrom}
                                    customInput={<FormControl className="menu-form custom-datepicker require-field" size="sm" placeholder="dd/mm/yyyy" />}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    placeholderText="dd/MM/yyyy HH:mm"
                                    showTimeInput
                                    isClearable
                                />
                            </Col>
                            <Col lg={1} />
                            <Col lg={1.5}>
                                <Form.Label className="font-weight-bold" style={{ marginTop: "2px" }}>Job Start To :</Form.Label>
                            </Col>
                            <Col lg={2}>
                                <DatePicker
                                    selected={this.state.bindTo}
                                    onChange={this.handleChangeTo}
                                    customInput={<FormControl className="menu-form custom-datepicker require-field" size="sm" placeholder="dd/mm/yyyy" />}
                                    dateFormat="dd/MM/yyyy HH:mm"
                                    placeholderText="dd/MM/yyyy HH:mm"
                                    showTimeInput
                                    isClearable
                                />
                            </Col>
                            <Col lg={3} />
                            <Col lg={2}>
                                <Button variant="info" size="sm" className="mx-1 btn btn-block" onClick={() => this.props.handleSearch(this.state.searchFrom, this.state.searchTo)} ><FaSearch /> Search</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default SearchCriteria;